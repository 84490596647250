import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-main text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-main px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl w-10/12 h-1/12`;
  const imageCss2 = tw`rounded-4xl w-full`;

  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Călătoritul cu transportul în comun <HighlightedText>făcut ușor</HighlightedText></>}
        description="Busify permite localizarea în timp real a mijloacelor de transport în comun din Cluj!"
        imageSrc="https://images.unsplash.com/photo-1680539486100-b7e9291c8e3a?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Încearcă acum!"
        primaryButtonUrl="https://app.busify.ro/"
      />
      <div id='maimulte'></div>
      <MainFeature
        subheading={<Subheading>Est. 2024</Subheading>}
        heading={
          <>
            Locuiești în Cluj? Beneficiază și tu de
            <wbr /> <HighlightedText>cea mai faină aplicație</HighlightedText>
          </>
        }
        description={
          <Description>
            * Platforma este complet gratuită și independentă de CTP Cluj-Napoca
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Încearcă acum!"
        primaryButtonUrl="https://app.busify.ro/"
        imageSrc={
          "https://0x0.st/XaG6.png"
        }
        imageCss={imageCss2}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <Features
        heading={
          <>
            Ce <HighlightedText>oferim ?</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: 'https://img.icons8.com/?size=100&id=241&format=png&color=000000',
            title: "Locații live",
            description: "Locația live a tuturor mijloacelor de transport, actualizate la fiecare 10s, aflate pe o hartă interactivă",
            url: "https://app.busify.ro/"
          },
          {
            imageSrc: 'https://img.icons8.com/?size=100&id=3524&format=png&color=000000',
            title: "Orare",
            description: "Orarele liniilor, ușor de urmărit, afișate în așa fel încât să nu mai incurci orarul din weekend-uri cu cel din timpul săptămânii",
            url: "https://app.busify.ro/orare"
          },
          {
            imageSrc: 'https://img.icons8.com/?size=100&id=3723&format=png&color=000000',
            title: "Destinații",
            description: "Mergi într-un loc nou? Nu-i bai, Busify se transformă în ghidul tău urban oricând ai nevoie",
            url: "https://app.busify.ro/map/destinatii"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>Proiecte</Subheading>}
        heading={<>Inițiativa <HighlightedText>Unde mi-i busu ?</HighlightedText></>}
        description={<>Prin amplasarea codurilor QR în stații, creștem expunerea Busify printr-un proiect aparte. <br /> <br/> Prin scanarea codului, utilizatorii pot accesa rapid o interfață care să le permită să vizualizeze linia pe o așteaptă, sau chiar toate liniile!</>}
        primaryButtonText="Încearcă și tu!"
        primaryButtonUrl="https://app.busify.ro/map/undemiibusu"
        imageInsideDiv={false}
        imageSrc="https://0x0.st/XM18.png"
        imageCss={Object.assign(tw`bg-cover`, imageCss2)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <DownloadApp
        subheading="Descarcă aplicația"
        text={<>Descarcă și tu aplicația <HighlightedTextInverse> Busify !</HighlightedTextInverse></>}
        link1Text="În curând"
        link2Text="În curând"
      />
      <Footer />
    </AnimationRevealPage>
  );
}
