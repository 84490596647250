import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: July 7, 2024</p>

            <p>
              These Terms of Use ("Terms") govern your use of our public transportation tracking app ("Busify"). By using our App, you agree to be bound by these Terms. If you do not agree with these Terms, you should not use our App.
            </p>

            <h1>Use of app</h1>
            <p>
              Our App provides you with public transportation tracking services. You may use our App only for lawful purposes and in accordance with these Terms. You agree not to use our App:

              In any way that violates any applicable federal, state, local, or international law or regulation
              For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way
              To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
              To impersonate or attempt to impersonate us, our employees, another user, or any other person or entity
            </p>

            <h1>
              Intellectual property
            </h1>
            <p>
              Our App and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio) are owned by us, our licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

              You may use our App only for your personal, non-commercial use. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our App.
            </p>


            <h1>
              Disclaimer warranties
            </h1>
            <p>
              Our App is provided "as is" and "as available" without warranty of any kind, whether express or implied. We do not warrant that our App will be uninterrupted or error-free, that defects will be corrected, or that our App or the servers that make it available are free of viruses or other harmful components.
            </p>

            <h1>Limitation of liability</h1>
            <p>
              In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our App. We shall not be liable for any damages whatsoever resulting from any:

              Errors, mistakes, or inaccuracies of content
              Personal injury or property damage of any nature whatsoever resulting from your access to and use of our App
              Unauthorized access to or use of our servers or any personal information stored on them
              Interruption or cessation of transmission to or from our App
              Bugs, viruses, Trojan horses, or other harmful code that may be transmitted to or through our App by any third party
            </p>

            <h1>
              Indemnification
            </h1>
            <p>
              You agree to indemnify and hold us harmless from and against any claims, liabilities, damages, losses, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of our App or your violation of these Terms.

              Changes to Our Terms of Use:
              We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our App.
            </p>

            <h1>
              Governing law
            </h1>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of Romania. Any legal suit, action, or proceeding arising out of or related to these Terms or our App shall be instituted exclusively in the federal or state courts located in Romania.
            </p>

            <h1>
              Contact
            </h1>
            <p>
              If you have any questions or concerns about these Terms or our App, please <a href='/contactus'>contact us by clicking here</a>.
              <br />
              <br />
              By using our App, you agree to the terms of these Terms of Use.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
