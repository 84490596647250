import React from "react";
import GlobalStyles from 'styles/GlobalStyles';

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import ThankYouPage from "ThankYouPage.js";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ContactUs from "pages/ContactUs";
import TermsOfService from "pages/TermsOfService";

import RestaurantLandingPage from "demos/RestaurantLandingPage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {

  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<RestaurantLandingPage />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/termsofservice' element={<TermsOfService />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='*' element={<RestaurantLandingPage />} />
        </Routes>
      </Router>
    </>
  );
}
