import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: July 7, 2024</p>

            <p>
              This Privacy Policy explains how our app tracks and uses your information when you use our public transportation tracking services. By using our app, you consent to the practices described in this policy.

              Information We Collect: When you use our app, we may collect and store the following information:
              Your device's unique identifier
              Your device's location data
              Your IP address
              Information about the public transportation vehicles you are tracking
              We use your device's location data to provide you with the accurate location of public transportation vehicles in your area. This data is collected and used for statistical purposes only and cannot be used to identify you personally.

              How We Use Your Information: We use the information we collect to:
              Provide you with public transportation tracking services
              Improve the functionality and features of our app
              Analyze and report on usage trends and patterns
              Enforce our policies and legal requirements
            </p>

            <h1>
              Disclosure of information
            </h1>
            <p>
              We may disclose your information to third-party service providers who assist us in providing our public transportation tracking services. We may also disclose your information if required by law or to protect our rights, property, or safety.
            </p>

            <h1>
              Data retention
            </h1>
            <p>
              We retain your information only as long as necessary to provide our public transportation tracking services and for statistical analysis. We will delete your information when it is no longer needed for these purposes.
            </p>

            <h1>
              Security
            </h1>
            <p>
              We take appropriate measures to protect the security of your information. However, no data transmission over the internet or mobile networks can be guaranteed to be 100% secure.
            </p>

            <h1>
              Changes to our Privacy Policy
            </h1>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our app.
            </p>

            <h1>
              Contact
            </h1>
            <p>
              If you have any questions or concerns about our Privacy Policy or practices, please contact us at mihnea.bondor@gmail.com. By using our app, you agree to the terms of this Privacy Policy.
            </p>

            <h1>
              Subscriptions
            </h1>
            <p>
              Thank you for your interest in subscribing to our donation-based subscription plan. By subscribing, you are not only gaining access to our exclusive directions feature, but you are also contributing to our ongoing efforts to improve and maintain our service.

              Here are the terms of our subscription:
              Subscription Fee: The subscription fee is a monthly donation of $1.99, which will be charged to your account on a recurring basis.
              Access to Directions Feature: With your subscription, you will have unlimited access to our directions feature, allowing you to plan your transit routes more efficiently.
              Unlimited Transit Vehicles: As a subscriber, you will be able to add an unlimited number of transit vehicles to your favorites list, instead of the previous limit of 2.
              Automatic Renewal: Your subscription will automatically renew each month, unless you cancel it.
              Cancellation: You may cancel your subscription at any time. However, please note that we do not offer refunds for any partial subscription periods.
              Changes to Subscription Terms: We reserve the right to change the terms of our subscription at any time. If we make any changes, we will notify you via email.
              Thank you for supporting our service through your subscription. If you have any questions or concerns, please don't hesitate to contact us.
            </p>

            <h1>
              Donations
            </h1>
            <p>
              Here are the terms for our donation system:
              Donation Amount: The donation amount is voluntary and can be any amount you choose. We appreciate any contribution you can make to support the development of our app.
              One-Time Donation: The donation is a one-time payment, and will not automatically renew. If you would like to make another donation in the future, you will need to do so manually.
              Non-Refundable: Donations made through our system are non-refundable. Once a donation has been made, we cannot offer a refund or exchange for any reason.
              Support for App Development: donation will go directly towards supporting the ongoing development and maintenance of our app. This includes paying for server costs, hiring developers, and other expenses associated with creating and improving the app.
              No Additional Benefits: Please note that making a donation does not unlock any additional features or benefits within the app. This is simply a way for you to support the ongoing development of our service.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
